<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/individual_manage_homeview/individual_race_management',
                }"
                >个人赛赛务工具</el-breadcrumb-item
            >
            <el-breadcrumb-item>网络赛考场</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 考场筛选 -->
        <el-row :gutter="20">
            <el-col :span="16">
                <el-card>
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">考场筛选</span>
                        </el-col>
                    </el-row>
                    <el-form
                        :model="queryForm"
                        class="filter_Form"
                        label-width="90px"
                        ref="queryFormRef"
                    >
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="赛区" prop="districtid">
                                    <el-select
                                        placeholder="请选择"
                                        style="width: 100%"
                                        v-model="queryForm.districtid"
                                        @change="districtChangeClicked"
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in dict_district"
                                            :key="item.id"
                                            :label="item.districtName"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="赛程"
                                    prop="raceScheduleId"
                                >
                                    <el-select
                                        placeholder="请选择"
                                        style="width: 100%"
                                        disabled
                                        v-model="queryForm.raceScheduleId"
                                        clearable
                                    >
                                        <el-option
                                            v-for="item in dict_matchType"
                                            :key="item.id"
                                            :label="item.scheduleName"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="组别" prop="levelstr">
                                    <el-select
                                        placeholder="请选择"
                                        style="width: 100%"
                                        v-model="queryForm.levelstr"
                                    >
                                        <el-option
                                            v-for="item in dict_examGroup"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="考场名称" prop="name">
                                    <el-input
                                        v-model="queryForm.name"
                                        placeholder="请输入"
                                        clearable
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="场次" prop="ampm">
                                    <el-select
                                        placeholder="请选择"
                                        style="width: 100%"
                                        v-model="queryForm.ampm"
                                    >
                                        <el-option
                                            v-for="item in dict_examState"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="是否启用" prop="state">
                                    <el-select
                                        placeholder="请选择"
                                        style="width: 100%"
                                        v-model="queryForm.state"
                                    >
                                        <el-option
                                            v-for="item in dictExamstatus"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-row :gutter="20" type="flex" justify="end">
                        <el-button type="primary" @click="handleToSearch"
                            >查询</el-button
                        >
                        <el-button type="primary" plain @click="handleToReset"
                            >重置</el-button
                        >
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card>
                    <el-row>
                        <el-col>
                            <div>考场数量管理</div>
                        </el-col>
                    </el-row>
                    <el-form
                        :model="examNumForm"
                        class="filter_Form"
                        label-width="80px"
                        :rules="examNumInfoRules"
                        ref="examNumFormRef"
                    >
                        <el-row class="el-pagination">
                            <el-col :span="20">
                                <el-form-item
                                    label="上午场数量"
                                    prop="amNum"
                                    label-width="120px"
                                >
                                    <el-input
                                        type="number"
                                        v-model="examNumForm.amNum"
                                        :placeholder="
                                            queryForm.districtid === ''
                                                ? '请先选择赛区'
                                                : '请输入考场数'
                                        "
                                        :disabled="queryForm.districtid === ''"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item
                                    label="下午场数量"
                                    prop="pmNum"
                                    label-width="120px"
                                >
                                    <el-input
                                        type="number"
                                        v-model="examNumForm.pmNum"
                                        :placeholder="
                                            queryForm.districtid === ''
                                                ? '请先选择赛区'
                                                : '请输入考场数'
                                        "
                                        :disabled="queryForm.districtid === ''"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-row type="flex" justify="end">
                        <el-button
                            type="primary"
                            @click="savaExamRoomDataClicked('am')"
                            >保存场次</el-button
                        >
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="4">
                    <span class="title_class">考场列表</span>
                </el-col>
                <el-col :span="20">
                    <div class="div_algin_right">
                        <el-button
                            class="mr-10"
                            type="primary"
                            plain
                            icon="el-icon-sort"
                            @click="mergeExamRoomDialogVisible = true"
                            >合并考场</el-button
                        >
                        <el-button
                            class="mr-10"
                            type="primary"
                            plain
                            icon="el-icon-refresh-right"
                            @click="resetStudentsDialogVisible = true"
                            >重置选手信息</el-button
                        >
                        <el-button
                            class="mr-10"
                            type="default"
                            icon="el-icon-upload2"
                            @click="showImportDialog"
                            >批量导入考场</el-button
                        >
                        <el-button
                            class="mr-10"
                            type="primary"
                            plain
                            icon="el-icon-download"
                            @click="exportExcel"
                            >考场信息导出</el-button
                        >
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            @click="handleToCreate"
                            >新建考场</el-button
                        >
                        <el-button
                            class="mr-10"
                            type="primary"
                            plain
                            icon="el-icon-download"
                            @click="examStudentsDialogVisible = true"
                            >考场考生导出</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column
                    label="序号"
                    type="index"
                    width="50px"
                ></el-table-column>
                <el-table-column label="赛季" prop="season">
                    <template slot-scope="{ row }">
                        {{ row.season || "-" }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="赛区"
                    prop="districtid"
                    :formatter="districtFormatter"
                >
                </el-table-column>
                <el-table-column label="考场名称" prop="name">
                    <template slot-scope="{ row }">
                        <el-link type="primary" @click="handleToDetails(row)">{{
                            row.name || "-"
                        }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column
                    label="组别"
                    prop="levelstr"
                    :formatter="levelFormat"
                ></el-table-column>
                <el-table-column
                    label="场次"
                    prop="ampm"
                    :formatter="ampmFormatter"
                >
                </el-table-column>
                <el-table-column label="开始时间" prop="startTime" width="90px">
                    <template slot-scope="{ row }">
                        {{ row.startTime || "-" }}
                    </template>
                </el-table-column>
                <el-table-column label="结束时间" prop="endTime" width="90px">
                    <template slot-scope="{ row }">
                        {{ row.endTime || "-" }}
                    </template>
                </el-table-column>
                <el-table-column label="详细地址" prop="address" width="180px">
                    <template slot-scope="{ row }">
                        {{ row.address || "-" }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="比赛形式"
                    prop="type"
                    :formatter="typeFormatter"
                >
                </el-table-column>
                <el-table-column
                    label="会议号"
                    prop="meetingNumber"
                    width="110px"
                >
                    <template slot-scope="{ row }">
                        {{ row.meetingNumber ? row.meetingNumber : "-" }}
                    </template>
                </el-table-column>
                <!-- <el-table-column
                          label="复赛形式"
                          prop="roomForm"
                          :show-overflow-tooltip="true"
                      >
                          <template slot-scope="{ row }">
                              {{ row.roomForm || "-" }}
                          </template>
                      </el-table-column> -->
                <el-table-column label="考位数量" prop="sitnum">
                    <template slot-scope="{ row }">
                        {{ row.sitnum || "-" }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="已选数量"
                    prop="cursit"
                ></el-table-column>
                <el-table-column label="状态" prop="state">
                    <template slot-scope="{ row }">
                        <el-tag
                            size="mini"
                            :type="row.state ? 'success' : 'danger'"
                            >{{ row.state ? "开放" : "关闭" }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" fixed="right">
                    <template slot-scope="{ row }">
                        <el-switch
                            :active-value="true"
                            :inactive-value="false"
                            v-model="row.state"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="stateChange(row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="150px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            style="margin-right: 12px"
                            @click="showEditDialog(scope.row)"
                            >修改</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            style="margin-right: 12px"
                            @click="handleToDetails(scope.row)"
                            >选手管理</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageInfo.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 新增、修改考场 -->
        <el-dialog
            :title="`${editType === 0 ? '新建' : '修改'}考场`"
            :visible.sync="dialogVisible"
            width="70%"
            @close="handleCloseDialog"
        >
            <el-form
                :model="dataForm"
                label-width="80px"
                ref="dataFormRef"
                :rules="dataFormRules"
            >
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="赛区" prop="districtid">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="dataForm.districtid"
                                @change="distChange"
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="赛程" prop="raceScheduleId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                disabled
                                v-model="dataForm.raceScheduleId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_matchType"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="组别" prop="levelstr">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="dataForm.levelstr"
                            >
                                <el-option
                                    v-for="item in dict_examGroup"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="考场标识" prop="roomstr">
                            <el-input
                                v-model="dataForm.roomstr"
                                placeholder="赛区首字母（例：BJ）"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="考场名称" prop="name">
                            <el-input
                                v-model="dataForm.name"
                                placeholder="请输入"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="考场编号" prop="num">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="dataForm.num"
                            >
                                <el-option
                                    v-for="item in classRomeList"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="考位数量" prop="sitnum">
                            <el-input
                                v-model="dataForm.sitnum"
                                placeholder="请输入"
                                type="number"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="已选考位" prop="cursit">
                            <el-input
                                v-model="dataForm.cursit"
                                placeholder="请输入"
                                type="number"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否已满" prop="fullState">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="dataForm.fullState"
                            >
                                <el-option
                                    v-for="item in dict_fullState"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="场次" prop="ampm">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="dataForm.ampm"
                            >
                                <el-option
                                    v-for="(item, index) in dict_examState"
                                    :key="index"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="选区名称" prop="areaName">
                            <el-input
                                v-model="dataForm.areaName"
                                placeholder="请输入10字以内"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="是否启用" prop="state">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="dataForm.state"
                            >
                                <el-option
                                    v-for="item in dictExamstatus"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="考场类型" prop="type">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="dataForm.type"
                            >
                                <el-option
                                    v-for="item in dict_examType"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="比赛时间" prop="dateRange">
                            <el-date-picker
                                width="100%"
                                v-model="dataForm.dateRange"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="daterangeChange"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8" v-if="dataForm.type === 'offline'">
                        <el-form-item label="考场地址" prop="address">
                            <el-input
                                v-model="dataForm.address"
                                placeholder="请输入"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm.type === 'offline'">
                        <el-form-item label="地点标志" prop="addrFlag">
                            <el-input
                                v-model="dataForm.addrFlag"
                                placeholder="请输入"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm.type === 'online'">
                        <el-form-item label="会议号" prop="meetingNumber">
                            <el-input
                                v-model="dataForm.meetingNumber"
                                placeholder="请输入"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="注意事项" prop="attionstr">
                            <el-input
                                v-model="dataForm.attionstr"
                                :rows="4"
                                placeholder="请输入注意事项，第一条前不需要加@，后面每条换行处敲“@”实现换行，例“1,xxx@2,xxx@3,xxx”"
                                type="textarea"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleToSubmit">{{
                    editType === 0 ? "新 建" : "保存修改"
                }}</el-button>
            </span>
        </el-dialog>

        <!-- 导入文件 -->
        <el-dialog
            width="500px"
            title="批量导入考场"
            :visible="importVisible"
            @close="handleToCLoseImport"
        >
            <el-form :model="importForm" label-width="80px" ref="importFormRef">
                <el-form-item label="赛区" prop="districtid">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="importForm.districtid"
                        @change="idChangeFn"
                    >
                        <el-option
                            v-for="item in dict_district"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="赛程" prop="raceScheduleId">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        disabled
                        v-model="importForm.raceScheduleId"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_matchType"
                            :key="item.id"
                            :label="item.scheduleName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="导入考场" prop="districtid">
                    <el-upload
                        ref="uploadFileRef"
                        :auto-upload="false"
                        :action="uploadFileRequest.uploadUrl"
                        :data="this.importForm"
                        :headers="uploadFileRequest.headerObj"
                        :disabled="
                            !importForm.districtid || !importForm.raceScheduleId
                        "
                        :before-upload="beforeUpload"
                        :limit="1"
                        :on-success="uploadSuccess"
                        :on-error="uploadError"
                    >
                        <el-button
                            :disabled="
                                !importForm.districtid ||
                                !importForm.raceScheduleId
                            "
                            class="mr-10"
                            type="primary"
                            >选择文件</el-button
                        >
                        <el-link type="primary" @click="downLoadFn"
                            >下载模版</el-link
                        >
                    </el-upload>
                </el-form-item>
                <el-row type="flex" justify="end" style="margin-top: 20px">
                    <el-col :span="4">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="importRoomData"
                            >保存</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <!-- 修改线下选考场时间 -->
        <el-dialog
            title="修改选考场时间"
            :visible.sync="timeDialogVisible"
            width="600px"
            @close="handleCloseTimeDialog"
        >
            <el-form
                label-width="130px"
                :model="editDistrictampm"
                :rules="editDistrictampmRules"
                ref="editDistrictampmRef"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item
                            label="选考场起止时间"
                            prop="chooseRoomStartEndTime"
                        >
                            <el-date-picker
                                style="width: 100%"
                                :default-time="['00:00:00', '23:59:59']"
                                v-model="
                                    editDistrictampm.chooseRoomStartEndTime
                                "
                                type="datetimerange"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @input="chooseRoomTimeChange"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="timeDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveDistrictTime"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 新增、修改考场 -->
        <el-dialog
            title="新增考场"
            :visible.sync="addSchoolExamdialogVisible"
            width="40%"
            @close="addSchoolExamCloseDialog"
        >
            <el-form
                :model="addSchoolExamForm"
                label-width="80px"
                ref="addSchoolExamRef"
                :rules="addSchoolExamRules"
            >
                <el-row>
                    <el-col :span="20">
                        <el-form-item label-width="120px" label="学校省市区">
                            <el-cascader
                                placeholder="请选择"
                                clearable
                                :options="areasData"
                                style="width: 100%"
                                :props="{
                                    expandTrigger: 'hover',
                                    label: 'name',
                                    value: 'areaId',
                                    children: 'areas',
                                }"
                                @change="provincesHandleChangeSearch"
                            ></el-cascader>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item
                            label-width="120px"
                            label="学校"
                            prop="schoolId"
                        >
                            <el-select
                                ref="schoolSelectRef"
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="addSchoolExamForm.schoolId"
                                clearable
                                filterable
                            >
                                <el-option
                                    v-for="item in provincesSchoolListSearch"
                                    :key="item.id"
                                    :label="item.schoolName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addSchoolExamdialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="saveSchoolData"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 导出考生信息 -->
        <el-dialog
            title="导出考生信息"
            :visible.sync="examStudentsDialogVisible"
            width="40%"
            @close="examStudentsCloseDialog"
        >
            <el-form
                :model="examStudentsForm"
                label-width="80px"
                ref="examStudentsRef"
                :rules="examStudentsRules"
            >
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="examStudentsForm.districtId"
                                filterable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="赛程" prop="raceScheduleId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                disabled
                                v-model="examStudentsForm.raceScheduleId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_matchType"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="examStudentsDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="deriveExamData"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 重置考生信息 -->
        <el-dialog
            title="重置考生信息"
            :visible.sync="resetStudentsDialogVisible"
            width="40%"
            @close="resetStudentsCloseDialog"
        >
            <el-form
                :model="resetStudentsForm"
                label-width="120px"
                ref="resetStudentsRef"
                :rules="resetStudentsRules"
            >
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="赛程" prop="raceScheduleId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                disabled
                                v-model="resetStudentsForm.raceScheduleId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_matchType"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选手手机号" prop="phone">
                            <el-input
                                v-model="resetStudentsForm.phone"
                                placeholder="请输入手机号"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetStudentsDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="resetPlayerInfo"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 合并考场Dialog -->
        <el-dialog
            title="考场合并"
            :visible.sync="mergeExamRoomDialogVisible"
            @close="mergeExamRoomDialogClose"
            width="35%"
        >
            <el-form
                ref="mergeExamRoomFormRef"
                :model="mergeExamRoomForm"
                :rules="mergeExamRoomFormRules"
                label-width="120px"
            >
                <el-form-item label="主考场" prop="mainExamRoomId">
                    <el-select
                        placeholder="请选择"
                        @change="mainExamRoomIdSelect"
                        v-model="mergeExamRoomForm.mainExamRoomId"
                        ref="mergeExamRoomRole"
                        style="width: 80%"
                        filterable
                    >
                        <el-option
                            v-for="item in dataList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="被合并考场" prop="mergeExamRoomIds">
                    <el-select
                        placeholder="请选择"
                        ref="mergeSelectExamRoomRole"
                        v-model="mergeExamRoomForm.mergeExamRoomIds"
                        :disable="mergeExamRoomForm.mainExamRoomId"
                        multiple
                        style="width: 80%"
                        filterable
                    >
                        <el-option
                            v-for="item in mergedArray"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="mergeExamRoomClick"
                    >确认合并</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
  queryExaminationListPage, getAllAreaList, removeExaminationRoomUserByPhone, examinationSchoolDelete, examinationSchoolCreate, getSchoolListWithCity, setExaminationTestPosition, addExaminationRoom, updateExaminationRoom,
  queryDistrictPage, queryDistrictTimePage, saveOrUpdateDistrictTime, mergeExamRoom, saveExamRoomNum, getSetNumInfo
} from '@/http/api'
export default {
  data () {
    return {
      searchSpan: 24,
      examNumSpan: 0,
      mergeExamRoomDialogVisible: false,
      resetStudentsDialogVisible: false,
      resetStudentsForm: {
        phone: '',
        raceScheduleId: this.$route.query.raceScheduleId
      },
      resetStudentsRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        raceScheduleId: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      examStudentsDialogVisible: false,
      areasData: [],
      provincesSchoolListSearch: [],
      addSchoolExamdialogVisible: false,
      dataList: [],
      addSchoolExamForm: {
        raceScheduleFlag: this.$route.query.raceScheduleId,
        schoolId: ''
      },
      examStudentsForm: {
        raceScheduleId: this.$route.query.raceScheduleId,
        districtId: ''
      },
      examStudentsRules: {
        raceScheduleId: [{ required: true, message: '请选择', trigger: 'change' }],
        districtId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      addSchoolExamRules: {
        schoolId: [{ required: true, message: '请选择学校', trigger: 'change' }]
      },
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 10
      },
      queryForm: {
        districtid: '',
        state: null,
        ampm: '',
        areaName: '',
        fullState: null,
        levelstr: '',
        name: '',
        num: null,
        raceScheduleId: this.$route.query.raceScheduleId,
        type: this.$route.query.type
      },
      dict_district: [],
      dict_examGroup: this.$userInfo.dict_examGroup(),
      dict_examState: this.$userInfo.dict_examState(),
      dictExamstatus: this.$userInfo.dictExamstatus(),
      dict_fullState: this.$userInfo.dict_fullState(),
      dict_examType: this.$userInfo.dict_examType(),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      time_form: ['上午', '下午'],
      dialogVisible: false,
      dataFormRules: {
        roomstr: [{ required: true, message: '请输入考场标识', trigger: 'blur' }],
        districtid: [{ required: true, message: '请选择赛区', trigger: 'change' }],
        raceScheduleId: [{ required: true, message: '请选择赛程', trigger: 'change' }],
        levelstr: [{ required: true, message: '请选择组别', trigger: 'change' }],
        num: [{ required: true, message: '请输入考场编号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入考场名称', trigger: 'blur' }],
        fullState: [{ required: true, message: '请选择是否已满', trigger: 'change' }],
        sitnum: [{ required: true, message: '请输入考位数量', trigger: 'blur' }],
        cursit: [{ required: true, message: '请输入已选考位', trigger: 'blur' }],
        attionstr: [{ required: true, message: '请输入注意事项', trigger: 'blur' }],
        areaName: [{ required: true, message: '请输入选区名称', trigger: 'blur' }],
        ampm: [{ required: true, message: '请选择场次', trigger: 'change' }],
        dateRange: [{ required: true, message: '请选择比赛时间', trigger: 'change' }],
        state: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
        type: [{ required: true, message: '请选择考场类型', trigger: 'change' }],
        meetingNumber: [{ required: true, message: '请输入会议号', trigger: 'blur' }],
        address: [{ required: true, message: '请输入考场地址', trigger: 'blur' }]
      },
      examNumInfoRules: {
        amNum: [{ required: true, message: '请输入考场数', trigger: 'blur' }],
        pmNum: [{ required: true, message: '请输入考场数', trigger: 'blur' }]
      },
      uploadFileRequest: {
        upLoadType: 'file',
        uploadUrl: this.$env.baseIP + 'examinationRoom/importExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      examNumForm: {
        pmNum: null,
        amNum: null,
        districtId: null,
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.$route.query.raceScheduleId
      },
      dataForm: {
        roomstr: '',
        districtid: '',
        addrFlag: '',
        districtName: '',
        raceScheduleId: this.$route.query.raceScheduleId,
        id: '',
        address: '',
        endTime: '',
        levelstr: null,
        name: '',
        attionstr: '',
        sitnum: '',
        startTime: '',
        roomForm: '',
        ampm: '',
        areaName: '',
        cursit: 0
      },
      editType: 0, // 弹窗0新建1修改
      editId: 0,
      importVisible: false,
      importForm: {
        districtid: '',
        raceScheduleId: this.$route.query.raceScheduleId
      },
      uploadInfo: {
        uploadUrl: `${this.$http.defaults.baseURL}examinationRoom/importExcel/`,
        headerObj: {
          Authorization: window.sessionStorage.getItem('Authorization')
        }
      },
      activeName: 'first',
      queryDistrictampm: {
        raceScheduleId: this.$route.query.raceScheduleId,
        districtId: '',
        schoolName: '',
        pageNum: 1,
        pageSize: 10
      },
      selectMergeExamRoomNameList: [],
      divisionList: [],
      time_total: 0,
      timeDialogVisible: false,
      editDistrictampm: {
        chooseRoomStartEndTime: null,
        choosestartTime: '',
        chooseendTime: ''
      },
      editDistrictampmRules: {
        chooseRoomStartEndTime: [{ required: true, message: '请选择选考场起止时间', trigger: 'change' }]
      },
      classRomeList: [],
      mergeExamRoomForm: {
        raceSchedule: this.$route.query.raceScheduleId,
        activityType: this.$chnEngStatusCode.activityType,
        mergeExamRoomIds: []
      },
      mergeExamRoomFormRules: {
        mainExamRoomId: [{ required: true, message: '请选择', trigger: 'change' }],
        mergeExamRoomIds: [{ required: true, message: '请选择', trigger: 'change', type: 'array' }]
      },
      mergedArray: []
    }
  },
  created () {
    for (let i = 1; i <= 30; i++) {
      let num = i.toString()
      if (num.length < 2) {
        num = '0' + num
      }
      this.classRomeList.push(num)
    }
    this.dict_matchType = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.dict_matchType = value })
    this.getAllAreaList()
    this.getDistrictList()
    this.getList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 保存场次数量
    savaExamRoomDataClicked (type) {
      this.$refs.examNumFormRef.validate(async valid => {
        if (!valid) return false
        this.examNumForm.ampm = type
        this.examNumForm.num = type === 'am' ? this.examNumForm.amNum : this.examNumForm.pmNum
        saveExamRoomNum(this.examNumForm).then((res) => {
          if (type === 'am') {
            this.savaExamRoomDataClicked('pm')
          } else {
            return this.$message.success('保存成功')
          }
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 赛区切换点击事件
    districtChangeClicked (val) {
      console.log('val', val)
      getSetNumInfo({ districtId: val, raceSchedule: this.queryForm.raceScheduleId }).then((res) => {
        this.searchSpan = 16
        this.examNumSpan = 8
        this.examNumForm.amNum = res.data.amNum
        this.examNumForm.pmNum = res.data.pmNum
        this.examNumForm.districtId = val
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 显示合并考场弹窗
    mergeExamRoom () {
      this.mergeExamRoomDialogVisible = true
    },
    // 关闭合并考场弹窗
    mergeExamRoomDialogClose () {
      this.mergedArray = []
      this.$refs.mergeExamRoomFormRef.resetFields()
    },
    // 合并按钮点击事件（显示确认合并弹窗）
    mergeExamRoomClick () {
      if (this.mergeExamRoomForm.mergeExamRoomIds.length === 0) {
        this.$confirm('请选择被合并的考场', '提示', {
          cancelButtonText: '确定',
          type: 'warning'
        }).then(async () => {
        }).catch(() => { })
      } else {
        this.$refs.mergeSelectExamRoomRole.selected.map(item => {
          this.selectMergeExamRoomNameList.push(item.label)
        })
        this.$confirm('此操作将合并考场' + this.selectMergeExamRoomNameList.join(',') + '到' + this.$refs.mergeExamRoomRole.selectedLabel + '是否继续?', '提示', {
          confirmButtonText: '确认合并',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          mergeExamRoom(this.mergeExamRoomForm).then((res) => {
            this.mergeExamRoomDialogVisible = false
            this.$message.success('合并成功')
            this.getList()
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => { })
      }
    },
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校省市区选择
    provincesHandleChangeSearch (value) {
      console.log('value', value)
      if (!value) return
      this.provincesSchoolListSearch = []
      this.addSchoolExamForm.schoolId = ''
      this.getSchoolListData(value, 1)
    },
    // 获取学校List
    getSchoolListData (schoolArea, type) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolListSearch = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000, divisionType: 0 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 重置选手信息
    resetPlayerInfo () {
      this.$refs.resetStudentsRef.validate(async valid => {
        if (!valid) return false
        removeExaminationRoomUserByPhone(this.resetStudentsForm).then((res) => {
          this.resetStudentsDialogVisible = false
          this.$message.success('重置成功')
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    mainExamRoomIdSelect () {
      this.mergedArray = this.dataList.filter(item => {
        return item.id !== this.mergeExamRoomForm.mainExamRoomId
      })
      console.log('mergedArray', this.mergedArray)
    },
    resetStudentsCloseDialog () {
      this.$refs.resetStudentsRef.resetFields()
    },
    handleToSearch () {
      this.pageInfo.pageNum = 1
      this.getList()
    },
    handleToReset () {
      this.searchSpan = 24
      this.examNumSpan = 0
      this.$refs.queryFormRef.resetFields()
      this.getList()
    },
    getList () {
      const params = { ...this.queryForm, ...this.pageInfo }
      queryExaminationListPage(params).then(res => {
        this.dataList = res.data.list
        this.total = res.data.total
      })
    },
    exportExcel () {
      const params = { ...this.queryForm, ...this.pageInfo }
      var downLoadUrl = this.$env.baseIP + 'examinationRoom/excelQueryExaminationListPage'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, params)
    },
    showImportDialog () {
      this.importVisible = true
    },
    handleToCLoseImport () {
      this.$refs.uploadFileRef.clearFiles()// 清除文件
      this.importVisible = false
      this.importForm.districtid = ''
    },
    idChangeFn (id) {
      this.uploadInfo.uploadUrl = `${this.uploadInfo.uploadUrl}${id}`
    },
    beforeUpload (file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$message.error('请选择excel文件上传')
        return false
      }
    },
    fileDataUpload () {
      this.$upLoadFileRequest.uploadFileResults(
        this.importForm, this.uploadFileRequest
      ).then(value => {
        this.fileDataList.push({ fileUrl: value.data.data })
        if (this.fileDataList.length === this.$refs.fileDataRef.uploadFiles.length) {
          this.upLoadCourseData()
        }
      })
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.$message.success('文件上传成功')
        this.handleToSearch()
        this.handleToCLoseImport()
      } else {
        this.$message.error(res.message)
        this.$refs.uploadFileRef.clearFiles()// 清除文件
      }
    },
    uploadError (error) {
      console.log('uploadError', error)
    },
    importRoomData () {
      this.$confirm('请确认已有考场是否有人已选考位，继续上传会将已选考位信息清空，选手需要重新选择考位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$refs.uploadFileRef.submit()
      })
    },
    downLoadFn () {
      var downLoadUrl = this.$env.baseIP + 'examinationRoom/downLoad'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, {})
    },
    handleToCreate () {
      this.editType = 0
      this.dialogVisible = true
    },
    handleCloseDialog () {
      this.dataForm = {
        activityType: this.$chnEngStatusCode.activityType,
        districtid: '',
        raceScheduleId: this.$route.query.raceScheduleId,
        districtName: '',
        id: '',
        address: '',
        endTime: '',
        levelstr: '',
        name: '',
        attionstr: '',
        sitnum: '',
        startTime: '',
        season: '',
        roomForm: '',
        ampm: '',
        areaName: ''
      }
      if (this.hideUploadAdd) this.hideUploadAdd = false
      this.$refs.dataFormRef.resetFields()
      this.dialogVisible = false
    },
    handleSizeChange (e) {
      this.pageInfo.pageSize = e
      this.getList()
    },
    handleCurrentChange (e) {
      this.pageInfo.pageNum = e
      this.getList()
    },
    showEditDialog (row) {
      this.dataForm = row
      // 比赛时间处理
      const dateRange = []
      dateRange[0] = new Date(row.startTime)
      dateRange[1] = new Date(row.endTime)
      this.$set(this.dataForm, 'dateRange', dateRange)
      this.editId = row.id
      this.editType = 1
      this.dialogVisible = true
    },
    async stateChange (row) {
      const { id, state } = row
      setExaminationTestPosition({ id, state }).then(res => {
        this.getList()
      })
    },
    handleToSubmit () {
      this.$refs.dataFormRef.validate(async valid => {
        if (!valid) return false
        if (this.editType === 0) delete this.dataForm.id
        delete this.dataForm.dateRange
        Object.keys(this.dataForm).forEach(key => {
          if (key === 'startTime' || key === 'endTime') {
            this.dataForm[key] = this.$moment(this.dataForm[key]).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        console.log('dataForm', this.dataForm)
        if (this.editType === 1) {
          updateExaminationRoom(this.dataForm).then(res => {
            this.$message.success('保存成功')
            this.handleCloseDialog()
            this.getList()
          })
        } else {
          addExaminationRoom(this.dataForm).then(res => {
            this.$message.success('保存成功')
            this.handleCloseDialog()
            this.getList()
          })
        }
      })
    },
    // 导出考场考生信息
    deriveExamData () {
      this.$refs.examStudentsRef.validate(async valid => {
        var downLoadUrl = this.$env.baseIP + 'examinationUser/excelExportByDistrict'
        this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.examStudentsForm)
      })
    },
    handleToDetails (row) {
      this.$router.push({
        path: '/individual_racing_tool/examination_room_details',
        query: {
          id: row.id
        }
      })
    },
    daterangeChange (e) {
      if (e.length === 2) {
        this.dataForm.startTime = e[0]
        this.dataForm.endTime = e[1]
      }
    },
    distChange (e) {
      this.dataForm.districtName = this.dict_district.find(item => item.id === e).districtName
    },
    tabHandleClick () {
      //
      if (this.activeName === 'first') {
        this.getList()
      } else {
        this.getQueryDistrictTimePage()
      }
    },
    handleTimeSizeChange (e) {
      this.queryDistrictampm.pageSize = e
      this.getQueryDistrictTimePage()
    },
    handleTimeCurrentChange (e) {
      this.queryDistrictampm.pageNum = e
      this.getQueryDistrictTimePage()
    },
    // 获取赛区列表数据
    getQueryDistrictTimePage () {
      this.$refs.queryDistrictampmRef.validate(async valid => {
        if (!valid) return
        let formDatas = {}
        formDatas = this.queryDistrictampm
        queryDistrictTimePage(formDatas).then((res) => {
          this.divisionList = res.data.list
          this.time_total = res.data.total
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryDistrictampmRef.resetFields()
      this.getQueryDistrictTimePage()
    },
    // 比赛类型转文字
    matchTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_matchType) {
        if (Object.hasOwnProperty.call(this.dict_matchType, key)) {
          const element = this.dict_matchType[key]
          if (element.id === data.raceSchedule + '') {
            temp = element.scheduleName
          }
        }
      }
      return temp
    },
    showEditDistrictTimeDialog (row) {
      console.log(row)
      this.editDistrictampm = JSON.parse(JSON.stringify(row))
      this.editDistrictampm.choosestartTime = row.choosestartTime
      this.editDistrictampm.chooseendTime = row.chooseendTime
      this.editDistrictampm.chooseRoomStartEndTime = [row.choosestartTime, row.chooseendTime]
      this.timeDialogVisible = true
    },
    handleCloseTimeDialog () {
      this.timeDialogVisible = false
    },
    // 修改和新增时间信息
    saveDistrictTime () {
      console.log('this.editDistrictampm', this.editDistrictampm)
      this.$refs.editDistrictampmRef.validate(async valid => {
        if (!valid) return
        this.editDistrictampm.districtType = 0
        saveOrUpdateDistrictTime(this.editDistrictampm).then((res) => {
          console.log('saveOrUpdateDistrictTime', res)
          this.timeDialogVisible = false
          this.getQueryDistrictTimePage()
        }).catch((err) => {
          console.log('err1111', err)
        })
      })
    },
    chooseRoomTimeChange (e) {
      if (this.editDistrictampm.chooseRoomStartEndTime && this.editDistrictampm.chooseRoomStartEndTime.length === 2) {
        this.editDistrictampm.choosestartTime = this.editDistrictampm.chooseRoomStartEndTime[0]
        this.editDistrictampm.chooseendTime = this.editDistrictampm.chooseRoomStartEndTime[1]
      } else {
        this.editDistrictampm.chooseRoomStartEndTime = null
      }
      this.$forceUpdate()
    },
    // 新增学校保存事件
    saveSchoolData () {
      examinationSchoolCreate(this.addSchoolExamForm).then((res) => {
        this.addSchoolExamdialogVisible = false
        this.getQueryDistrictTimePage()
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    addSchoolExamCloseDialog () {
      this.$refs.addSchoolExamRef.resetFields()
    },
    // 删除学校弹窗
    showdeleteDialog (rowData) {
      console.log('rowData', rowData)
      this.$confirm('是否确认删除该考场?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        examinationSchoolDelete({ id: rowData.id }).then((res) => {
          this.$message.success('删除成功')
          this.getQueryDistrictTimePage()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => { })
    },
    examStudentsCloseDialog () {
      this.$refs.examStudentsRef.resetFields()
    },
    levelFormat (data) {
      var temp = '未设置'
      for (const key in this.dict_examGroup) {
        if (Object.hasOwnProperty.call(this.dict_examGroup, key)) {
          const element = this.dict_examGroup[key]
          if (element.dictValue === data.levelstr) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    districtFormatter (data) {
      var tempName = '未设置'
      this.dict_district.forEach(element => {
        if (element.id === data.districtid) {
          tempName = element.districtName
        }
      })
      return tempName
    },
    typeFormatter (data) {
      var tempName = '未设置'
      this.dict_examType.forEach(element => {
        if (element.dictValue === data.type) {
          tempName = element.dictLabel
        }
      })
      return tempName
    },
    ampmFormatter (data) {
      var tempName = '未设置'
      this.dict_examState.forEach(element => {
        if (element.dictValue === data.ampm) {
          tempName = element.dictLabel
        }
      })
      return tempName
    }
  }
}
</script>
<style lang="less" scoped>
.mr-10 {
    margin-right: 10px;
}
.div_algin_right {
    text-align: right;
}
::v-deep .el-tabs--card > .el-tabs__header {
    background: #fff;
    margin-bottom: 0;
    border-bottom: none;
    border-top-left-radius: 4px;
}
</style>
